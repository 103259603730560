import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import {
	view_admin_dashboard,
	view_contractor_dashboard,
	view_director_dashboard,
	view_parent_dashboard, view_perifereia_dashboard
} from "../../../helpers/rights";
import PermissionHOC from "../../permission/components/permissionHOC/permissionHOC";
import { FAQS } from "../locales/namespaces";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const Faqs = (props) => {
	const { t, ready } = useTranslation(FAQS)
	
	const [expanded, setExpanded] = useState('panel1');
	
	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};
	
	return (
		<Box className={'faqs_index'} sx={{ marginTop: '10px' }}>
			<Grid container spacing={1}>
				<Grid size={{ md: 2, sm: 0, xs: 0 }}>
				
				</Grid>
				<Grid size={{ md: 8, sm: 12, xs: 12 }}>
					<PageContentWrapper>
						<PageTitle
							title={t('FAQs')}/>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_parent_dashboard]}>
							<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel1-content"
									id="panel1-header"
								>
									{t('What information can I view about the student?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('On the parent`s main tab, you can view')}:
									<ul>
										<li>
											{t('The student`s full name.')}
										</li>
										<li>
											{t('The home address.')}
										</li>
										<li>
											{t('The school and school year.')}
										</li>
										<li>
											{t('The status of the transportation request.')}
										</li>
									</ul>
								
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_parent_dashboard]}>
							<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel2-content"
									id="panel2-header"
								>
									{t('How can I view the route stops on a map?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Select a route by clicking on it.')}
									<br></br>
									{t('The boarding and disembarkation stops will appear with red pins on the map.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_parent_dashboard]}>
							<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel3-content"
									id="panel3-header"
								>
									{t('How can I view the route stops on a map along with the current home address?')}
								</AccordionSummary>
								<AccordionDetails>
									
									{t('Click on "Show on the map" and select a route.')}
									<br></br>
									{t('The boarding and disembarkation stops will appear with red pins on the map.')}
									<br></br>
									{t('The home address will appear with a blue pin.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_parent_dashboard]}>
							<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel4-content"
									id="panel4-header"
								>
									{t('How can I change the student`s home address?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the pencil icon next to the address information and')}:
									<ul>
										<li>
											{t('Move the blue pin on the interactive map to the new address.')}
										</li>
										<li>
											{t('Select "Save Point" for a specific route, for all routes of the specific student, or for all routes of all students.')}
										</li>
										<li>
											{t('The change is sent to the school unit.')}
										</li>
										<li>
											{t('The new address pending approval will appear with a green pin.')}
										</li>
									</ul>
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_parent_dashboard]}>
							<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel5-content"
									id="panel5-header"
								>
									{t('Where can I see updates relevant to me?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('In the "Announcements" tab, where posts are displayed in chronological order.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_contractor_dashboard]}>
							<Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel11-content"
									id="panel11-header"
								>
									{t('What information appears on the home page?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('The home page includes')}:
									<ul>
										<li>
											{t('Your Tax Identification Number (TIN).')}
										</li>
										<li>
											{t('Cards with your assigned routes.')}
										</li>
										
										<li>{t('Each card contains')}:</li>
										<ul>
											<li>
												{t('Route Title.')}
											</li>
											<li>
												{t('Route description (e.g., starting and ending points).')}
											</li>
											<li>
												{t('Start and End Dates.')}
											</li>
											<li>
												{t('Return information (if applicable).')}
											</li>
										</ul>
									</ul>
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_contractor_dashboard]}>
							<Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel12-content"
									id="panel12-header"
								>
									{t('How can I view detailed information about a route?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on a route card. A pop-up window will appear with')}:
									<ul>
										<li>
											{t('The title and route description.')}
										</li>
										<li>
											{t('The assigned price and the calculated price based on the mathematical formula.')}
										</li>
										<li>{t('A table with detailed dates and costs per route.')}:</li>
									</ul>
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_contractor_dashboard]}>
							<Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel13-content"
									id="panel13-header"
								>
									{t('What is the assigned price and the calculated price?')}
								</AccordionSummary>
								<AccordionDetails>
									<ul>
										<li>
											{t('Assigned Price')}: {t('The agreed price for the route.')}
										</li>
										<li>
											{t('Calculated Price')}: {t('The price automatically calculated by the system based on a mathematical formula.')}
										</li>
									</ul>
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC
							requiredPermissions={[view_admin_dashboard, view_contractor_dashboard, view_director_dashboard]}>
							<Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel14-content"
									id="panel14-header"
								>
									{t('Where can I view the announcements?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the "Announcements" tab in the top menu. A list of all posted updates will appear.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC
							requiredPermissions={[view_admin_dashboard, view_parent_dashboard, view_contractor_dashboard, view_director_dashboard]}>
							<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel6-content"
									id="panel6-header"
								>
									{t('How do I read a full announcement?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click either on the title of the announcement or the "See more..." link.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_parent_dashboard]}>
							<Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel7-content"
									id="panel7-header"
								>
									{t('What does the status "Pending" mean?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('It means that the student`s transportation request is still being processed and has not yet been completed.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						
						<PermissionHOC
							requiredPermissions={[view_admin_dashboard, view_parent_dashboard, view_contractor_dashboard]}>
							<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel8-content"
									id="panel8-header"
								>
									{t('How can I return to the home page?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the Region of Western Greece logo at the top left corner or select the "Home" tab in the navigation menu.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_director_dashboard]}>
							<Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel15-content"
									id="panel15-header"
								>
									{t('What is the "Address Changes" page?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('It is a section that records address changes submitted by parents.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_director_dashboard]}>
							<Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel16-content"
									id="panel16-header"
								>
									{t('How can I filter the address changes?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click the "Filters" button and apply criteria such as parent TIN, change date, or active status.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_director_dashboard]}>
							<Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel17-content"
									id="panel17-header"
								>
									{t('Can I export the address data?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Yes. Click the "Export" button and select the file format')}:
									<ul>
										<li>
											{t('CSV')}
										</li>
										<li>
											{t('Excel')}
										</li>
										<li>
											{t('Print')}
										</li>
									</ul>
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_director_dashboard]}>
							<Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel18-content"
									id="panel18-header"
								>
									{t('What appears on the Mails page?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('The Mails table includes all electronic messages sent from the system to your school unit.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_director_dashboard]}>
							<Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel19-content"
									id="panel19-header"
								>
									{t('How can I view the full content of an email?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the title of the message. A new page will open with all the details.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_perifereia_dashboard]}>
							<Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel20-content"
									id="panel20-header"
								>
									{t('How do I create a new announcement?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('On the "Announcements" page, click the "Add" button. Fill in the mandatory fields')}:
									<ul>
										<li>
											{t('Title')}
										</li>
										<li>
											{t('Content')}
										</li>
										<li>
											{t('Posting and expiration dates')}
										</li>
										<li>
											{t('User category (Type)')}
											<br></br>
											{t('Activate or deactivate the options "Important" and "Send email."')}
											<br></br>
											{t('Click "Save" to save.')}
										</li>
									</ul>
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_perifereia_dashboard]}>
							<Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel21-content"
									id="panel21-header"
								>
									{t('How can I edit an existing announcement?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the pencil icon next to the announcement in the table. The edit screen will open for making changes.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_perifereia_dashboard]}>
							<Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel22-content"
									id="panel22-header"
								>
									{t('How can I delete an announcement?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the trash icon next to the announcement.')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_perifereia_dashboard]}>
							<Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel23-content"
									id="panel23-header"
								>
									{t('How can I filter announcements?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click the "Filters" button and select')}:
									<ul>
										<li>
											{t('Column')}
										</li>
										<li>
											{t('Operator (e.g., "contains," "equals," "is after").')}
										</li>
										<li>
											{t('Search value.')}
											<br></br>
											{t('Click "Add Filter" to apply.')}
										</li>
									</ul>
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={[view_admin_dashboard, view_perifereia_dashboard]}>
							<Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel24-content"
									id="panel24-header"
								>
									{t('How can I export the announcement data?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the "Export" button and select the desired format')}:
									<ul>
										<li>
											{t('CSV')}
										</li>
										<li>
											{t('Excel')}
										</li>
										<li>
											{t('Print')}
										</li>
									</ul>
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC
							requiredPermissions={[view_admin_dashboard, view_parent_dashboard, view_contractor_dashboard, view_director_dashboard, view_perifereia_dashboard]}>
							<Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel9-content"
									id="panel9-header"
								>
									{t('How do I change the platform display language?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the globe icon at the top right corner and select the available language (e.g., Greek - GR).')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
						<PermissionHOC
							requiredPermissions={[view_admin_dashboard, view_parent_dashboard, view_contractor_dashboard, view_director_dashboard, view_perifereia_dashboard]}>
							<Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel10-content"
									id="panel10-header"
								>
									{t('How do I log out of my account?')}
								</AccordionSummary>
								<AccordionDetails>
									{t('Click on the user icon at the top right corner and select "Logout."')}
								</AccordionDetails>
							</Accordion>
						</PermissionHOC>
					</PageContentWrapper>
				</Grid>
				<Grid size={{ md: 2, sm: 0, xs: 0 }}>
				
				</Grid>
			</Grid>
		
		</Box>
	);
};

export default Faqs