import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.ADDRESSSCHANGES]: {
		el: {
			'Changes of Addresses' : 'Αλλαγές Διευθύνσεων',
			'TIN Parent' : 'ΑΦΜ Γονέα',
			'Child Name' : 'Όνομα παιδιού',
			'Old Lng' : 'Παλιό Lng',
			'Old Lat' : 'Παλιό Lat',
			'Date Change' : 'Ημ/νία αλλαγής',
			'Active' : 'Ενεργό',
		},
		en: {
		
		}
	},
}

export default locales