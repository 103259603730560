import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.FAQS]: {
		el: {
			'FAQs' : 'Συχνές ερωτήσεις',
			'On the parent`s main tab, you can view' : 'Στην αρχική καρτέλα του κηδεμόνα, μπορείτε να δείτε',
			'The student`s full name.' : 'Το ονοματεπώνυμο του μαθητή.',
			'The home address.' : 'Τη διεύθυνση κατοικίας.',
			'The school and school year.' :	'Το σχολείο και το σχολικό έτος.',
			'The status of the transportation request.' : 'Την κατάσταση της αίτησης μεταφοράς.',
			'What information can I view about the student?':'Ποιες πληροφορίες μπορώ να δω για τον μαθητή;',
			'How can I view the route stops on a map?':'Πώς μπορώ να δω τις στάσεις της διαδρομής σε χάρτη;',
			'Select a route by clicking on it.' : 'Επιλέξτε μια διαδρομή, πατώντας πάνω της.',
			'The boarding and disembarkation stops will appear with red pins on the map.' : 'Οι στάσεις επιβίβασης και αποβίβασης θα εμφανιστούν με κόκκινες πινέζες στον χάρτη.',
			'How can I view the route stops on a map along with the current home address?' : 'Πώς μπορώ να δω τις στάσεις της διαδρομής σε χάρτη με εμφάνιση της τρέχουσας διεύθυνσης κατοικίας;',
			'Click on "Show on the map" and select a route.' : 'Πατήστε το "Εμφάνιση στον χάρτη" και επιλέξτε μια διαδρομή.',
			'The home address will appear with a blue pin.' : 'Η διεύθυνση κατοικίας θα εμφανιστεί με μπλε πινέζα.',
			'How can I change the student`s home address?' : 'Πώς μπορώ να αλλάξω τη διεύθυνση κατοικίας του μαθητή;',
			'Click on the pencil icon next to the address information and' : 'Πατήστε το εικονίδιο με το μολύβι δίπλα στις πληροφορίες διεύθυνσης και',
			'Move the blue pin on the interactive map to the new address.' : 'Μετακινήστε τη μπλε πινέζα στον διαδραστικό χάρτη στη νέα διεύθυνση.',
			'Select "Save Point" for a specific route, for all routes of the specific student, or for all routes of all students.' : 'Επιλέξτε "Αποθήκευση Σημείου" για ένα συγκεκριμένο δρομολόγιο, για όλες τις διαδρομές του συγκεκριμένου μαθητή ή για όλες τις διαδρομές όλων των μαθητών.',
			'The change is sent to the school unit.' : 'Η αλλαγή αποστέλλεται στη σχολική μονάδα.',
			'The new address pending approval will appear with a green pin.' : 'Η νέα προς έγκριση διεύθυνση κατοικίας θα εμφανιστεί με πράσινη πινέζα.',
			'Where can I see updates relevant to me?' : 'Πού μπορώ να δω ενημερώσεις που με αφορούν;' ,
			'In the "Announcements" tab, where posts are displayed in chronological order.' : 'Στην καρτέλα "Ανακοινώσεις", όπου εμφανίζονται οι αναρτήσεις σε χρονολογική σειρά.' ,
			'How do I read a full announcement?' : 'Πώς διαβάζω μια πλήρη ανακοίνωση;' ,
			'Click either on the title of the announcement or the "See more..." link.' : 'Πατήστε είτε τον τίτλο της ανακοίνωσης είτε τον σύνδεσμο "Δείτε περισσότερα...".' ,
			'What does the status "Pending" mean?' : 'Τι σημαίνει η κατάσταση "Σε Εκκρεμότητα";' ,
			'It means that the student`s transportation request is still being processed and has not yet been completed.' : 'Σημαίνει ότι η αίτηση για τη μεταφορά του μαθητή βρίσκεται ακόμα υπό επεξεργασία και δεν έχει ολοκληρωθεί.' ,
			'How can I return to the home page?' : 'Πώς μπορώ να επιστρέψω στην αρχική σελίδα;' ,
			'Click on the Region of Western Greece logo at the top left corner or select the "Home" tab in the navigation menu.' : 'Πατήστε στο λογότυπο της Περιφέρειας Δυτικής Ελλάδας στην πάνω αριστερή γωνία ή επιλέξτε την καρτέλα "Αρχική" στο μενού πλοήγησης.' ,
			'How do I change the platform display language?' : 'Πώς αλλάζω τη γλώσσα εμφάνισης της πλατφόρμας;' ,
			'Click on the globe icon at the top right corner and select the available language (e.g., Greek - GR).' : 'Πατήστε το εικονίδιο της υδρογείου στην πάνω δεξιά γωνία και επιλέξτε τη διαθέσιμη γλώσσα (π.χ. Ελληνικά - GR).' ,
			'How do I log out of my account?' : 'Πώς αποσυνδέομαι από τον λογαριασμό μου;' ,
			'Click on the user icon at the top right corner and select "Logout."' : 'Πατήστε το εικονίδιο χρήστη στην πάνω δεξιά γωνία και επιλέξτε "Αποσύνδεση".' ,
			'What information appears on the home page?' : 'Τι πληροφορίες εμφανίζονται στην αρχική σελίδα;',
			'The home page includes' : 'Η αρχική σελίδα περιλαμβάνει',
			'Your Tax Identification Number (TIN).' : 'Το ΑΦΜ σας.',
			'Cards with your assigned routes.' : 'Κάρτες με τις αναθέσεις δρομολογίων σας.',
			'Each card contains' : 'Κάθε κάρτα περιέχει',
			'Route Title.' : 'Τίτλο Δρομολογίου.',
			'Route description (e.g., starting and ending points).' : 'Περιγραφή διαδρομής (π.χ. σημεία εκκίνησης και τερματισμού).',
			'Start and End Dates.' : 'Ημερομηνία Έναρξης και Λήξης.',
			'Return information (if applicable).' : 'Πληροφορίες επιστροφής (εάν υπάρχει).',
			'How can I view detailed information about a route?' : 'Πώς μπορώ να δω αναλυτικά τα στοιχεία ενός δρομολογίου;',
			'Click on a route card. A pop-up window will appear with' : 'Πατήστε στην καρτέλα ενός δρομολογίου. Θα εμφανιστεί αναδυόμενο παράθυρο με',
			'The title and route description.' : 'Τον τίτλο και την περιγραφή διαδρομής.',
			'The assigned price and the calculated price based on the mathematical formula.' : 'Την τιμή ανάθεσης και την τιμή μαθηματικού τύπου.',
			'A table with detailed dates and costs per route.' : 'Πίνακα με αναλυτικές ημερομηνίες και το κόστος ανά διαδρομή.',
			'What is the assigned price and the calculated price?' : 'Τι είναι η τιμή ανάθεσης και η τιμή μαθηματικού τύπου;',
			'Assigned Price' : 'Τιμή ανάθεσης',
			'The agreed price for the route.' : 'Η συμφωνημένη τιμή με την οποία αναλάβατε το δρομολόγιο.',
			'Calculated Price' : 'Τιμή μαθηματικού τύπου',
			'The price automatically calculated by the system based on a mathematical formula.': 'Η τιμή που υπολογίζεται αυτόματα από το σύστημα, βάσει μαθηματικού τύπου.',
			'Where can I view the announcements?' : 'Πού μπορώ να δω τις ανακοινώσεις;' ,
			'Click on the "Announcements" tab in the top menu. A list of all posted updates will appear.' : 'Πατήστε την καρτέλα "Ανακοινώσεις" στο επάνω μενού. Θα εμφανιστεί κατάλογος με όλες τις αναρτημένες ενημερώσεις.',
			'What is the "Address Changes" page?' : 'Τι είναι η σελίδα "Αλλαγές Διευθύνσεων";',
			'It is a section that records address changes submitted by parents.' : 'Είναι μια ενότητα που καταγράφει τις αλλαγές διευθύνσεων των μαθητών, τις οποίες δήλωσαν οι κηδεμόνες.',
			
			
			'How can I filter the address changes?' : 'Πώς μπορώ να φιλτράρω τις αλλαγές διευθύνσεων;',
			'Click the "Filters" button and apply criteria such as parent TIN, change date, or active status.' : 'Πατήστε το κουμπί "Φίλτρα" και εφαρμόστε κριτήρια όπως ΑΦΜ γονέα, ημερομηνία αλλαγής ή ενεργή κατάσταση.',
			'Can I export the address data?' : 'Μπορώ να εξάγω τα δεδομένα των διευθύνσεων;',
			'Yes. Click the "Export" button and select the file format' : 'Ναι. Πατήστε το κουμπί "Εξαγωγή" και επιλέξτε μορφή αρχείου',
			'CSV' : 'CSV',
			'Excel' : 'Excel',
			'Print' : 'Εκτύπωση.',
			'What appears on the Mails page?' : 'Τι εμφανίζεται στη σελίδα Mails;',
			'The Mails table includes all electronic messages sent from the system to your school unit.' : 'Ο πίνακας Mails περιλαμβάνει όλα τα ηλεκτρονικά μηνύματα που έχουν σταλεί από το σύστημα στη σχολική σας μονάδα.',
			'How can I view the full content of an email?' : 'Πώς μπορώ να δω το πλήρες περιεχόμενο ενός e-mail (ηλεκτρονικού μηνύματος);',
			'Click on the title of the message. A new page will open with all the details.' : 'Πατήστε στον τίτλο του μηνύματος. Θα ανοίξει μια νέα σελίδα με όλες τις λεπτομέρειες.',
			'How do I create a new announcement?' : 'Πώς δημιουργώ μια νέα ανακοίνωση;',
			'On the "Announcements" page, click the "Add" button. Fill in the mandatory fields' : 'Στη σελίδα "Ανακοινώσεις", πατήστε το κουμπί "Προσθήκη". Συμπληρώστε τα υποχρεωτικά πεδία',
			'Title': 'Τίτλος',
			'Content' : 'Περιεχόμενο',
			'Posting and expiration dates' : 'Ημερομηνία ανάρτησης και λήξης',
			'User category (Type)' : 'Κατηγορία χρήστη (Τύπος)',
			'Activate or deactivate the options "Important" and "Send email."' : 'Ενεργοποιήστε ή απενεργοποιήστε τις επιλογές "Σημαντική" και "Αποστολή e-mail".',
			'Click "Save" to save.' : 'Πατήστε "Save" για αποθήκευση.',
			'How can I edit an existing announcement?' : 'Πώς μπορώ να επεξεργαστώ μια υπάρχουσα ανακοίνωση;',
			'Click on the pencil icon next to the announcement in the table. The edit screen will open for making changes.' : 'Πατήστε το εικονίδιο μολυβιού δίπλα στην ανακοίνωση στον πίνακα. Θα ανοίξει η οθόνη επεξεργασίας για να κάνετε αλλαγές.',
			'How can I delete an announcement?' : 'Πώς μπορώ να διαγράψω μια ανακοίνωση;',
			'Click on the trash icon next to the announcement.' : 'Πατήστε το εικονίδιο κάδου δίπλα στην ανακοίνωση.',
			
			'How can I filter announcements?' : 'Πώς μπορώ να φιλτράρω τις ανακοινώσεις;',
			'Click the "Filters" button and select' : 'Πατήστε το κουμπί "Φίλτρα" και επιλέξτε',
			'Column' : 'Στήλη',
			'Operator (e.g., "contains," "equals," "is after").' : 'Τελεστή (π.χ., "περιέχει", "ισούται", "είναι μετά").',
			'Search value.' : 'Τιμή αναζήτησης.',
			'Click "Add Filter" to apply.' : 'Πατήστε "Προσθήκη Φίλτρου" για εφαρμογή.',
			'How can I export the announcement data?' : 'Πώς μπορώ να εξάγω τα δεδομένα των ανακοινώσεων;',
			'Click on the "Export" button and select the desired format' : 'Πατήστε το κουμπί "Εξαγωγή" και επιλέξτε τη μορφή που επιθυμείτε',
			
	
},
		en: {
		
		}
	},
}

export default locales