// @flow
import axios from "axios";
import React, { useEffect, useState } from 'react';
import useUserData from "../../../hooks/user/useUserData";

type Props = {};

const useRoutes = (props: Props) => {
	let { apiITYEToken, taxRegNo } = useUserData();

	const [routesData, setRoutesData] = useState([]);
	const [loading, setLoading] = useState(false);

	
	useEffect(() => {
		if (apiITYEToken) {
			let current = true;
			setLoading(true);
			axios.request({
				headers: {
					Authorization: `Bearer ${apiITYEToken}`
				},
				method: "GET",
				url: `https://api-devtransport-pde.cti.gr/api/openapi/contractor/routes?afm=` + taxRegNo
			}).then(response => {
				if (current) {
					setRoutesData(response.data.data);
				}
				setLoading(false);
			}).catch(error => {
				//TODO::enqueue error notification
				setLoading(false);
			})
			
			return () => {
				current = false;
			}
		}
	}, [])
	
	return {
		routesData,
		loading
	};
};

export default useRoutes