//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseUser, parseUserMessage} from "../../types/user/parse";

const selectLoading = createSelector(
	(state) => state.currentUser,
	(item) => item.get('loading')
)

const selectMessage = createSelector(
	(state) => state.currentUser.get('message'),
	(item) => parseUserMessage(item)
)

const selectLanguage = createSelector(
	(state) => state.currentUser,
	(item) => item.get('language')
)

const selectDecimalSeparator = createSelector(
	(state) => state.currentUser,
	(item) => item.get('decimalSeparator')
)

const selectThousandSeparator = createSelector(
	(state) => state.currentUser,
	(item) => item.get('thousandSeparator')
)

const selectDateFormat = createSelector(
	(state) => state.currentUser,
	(item) => item.get('dateFormat')
)

const selectTimeFormat = createSelector(
	(state) => state.currentUser,
	(item) => item.get('timeFormat')
)

const selectIsLoggedIn = createSelector(
	(state) => state.currentUser,
	(item) => item.get('isLoggedIn')
)

const selectToken = createSelector(
	(state) => state.currentUser,
	(item) => item.get('token')
)

const selectTokenExpirationDateTime = createSelector(
	(state) => state.currentUser,
	(item) => item.get('tokenExpirationDateTime')
)

const selectPasswordReset = createSelector(
	(state) => state.currentUser,
	(item) => item.get('passwordReset')
)

const selectRecoveryPwdTime = createSelector(
	(state) => state.currentUser,
	(item) => item.get('recoveryPwdTime')
)

const selectResetTime = createSelector(
	(state) => state.currentUser,
	(item) => item.get('resetTime')
)

const selectData = createSelector(
	[
		(state) => state.currentUser.get('data'),
		(state) => state.currentUser.get('language'),
	],
	(item, language) => parseUser(item, language)
)

const selectSuccessResetPass = createSelector(
	(state) => state.currentUser,
	(item) => item.get('successResetPass')
)


const selectLoadingITYE = createSelector(
	(state) => state.currentUser,
	(item) => item.get('loadingITYE')
)

const selectApiITYEToken = createSelector(
	(state) => state.currentUser,
	(item) => item.get('apiITYEToken')
)


const selectUserName = createSelector(
	(state) => state.currentUser,
	(item) => item.get('username')
)
const selectTaxRegNo = createSelector(
	(state) => state.currentUser,
	(item) => item.get('taxRegNo')
)

const useUserData = () => {
	const loading = useSelector(selectLoading)
	const message = useSelector(selectMessage)
	const language = useSelector(selectLanguage)
	const decimalSeparator = useSelector(selectDecimalSeparator)
	const thousandSeparator = useSelector(selectThousandSeparator)
	const dateFormat = useSelector(selectDateFormat)
	const timeFormat = useSelector(selectTimeFormat)
	const isLoggedIn = useSelector(selectIsLoggedIn)
	const token = useSelector(selectToken)
	const tokenExpirationDateTime = useSelector(selectTokenExpirationDateTime)
	const passwordReset = useSelector(selectPasswordReset)
	const recoveryPwdTime = useSelector(selectRecoveryPwdTime)
	const resetTime = useSelector(selectResetTime)
	const data = useSelector(selectData)
	const successResetPass = useSelector(selectSuccessResetPass)
	const loadingITYE = useSelector(selectLoadingITYE)
	const apiITYEToken = useSelector(selectApiITYEToken)
	const username = useSelector(selectUserName)
	const taxRegNo = useSelector(selectTaxRegNo)
	return {
		loading,
		message,
		language,
		decimalSeparator,
		thousandSeparator,
		dateFormat,
		timeFormat,
		isLoggedIn,
		token,
		tokenExpirationDateTime,
		passwordReset,
		recoveryPwdTime,
		resetTime,
		data,
		successResetPass,
		loadingITYE,
		apiITYEToken,
		username,
		taxRegNo
	}
}

export default useUserData