// @flow
import Grid from "@mui/material/Grid2";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { userLogin } from "../../actions/actions";
import * as Actions from "../../actions/actions";
import Logo from "../../components/custom/general/logo/logo";
import { API } from "../../helpers/constants";
import { handleAxiosErrors } from "../../helpers/errorHandling";
import { getRouteUrl } from "../../helpers/routing/getRouteUrl";
import { PAGE_LOGIN } from "../../locales/pages/namespaces";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import Form from "../../components/app/forms/login/form";
import LanguageSelect from "../../components/app/languageSelect/languageSelect";
import ForgotPassword from "../../components/app/dialogs/forgotPassword/forgotPassword";

import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";

import useLogin from "../../hooks/login/useLogin";
import './login.css'
import { ROUTE_ANNOUNCEMENT_NEWS } from "../../modules/announcements/routes/routes";
import { ROUTE_PAGE_HOME } from "../../routers/routes";
import LoginCard from "./loginCard";
import axios from "axios";

const Login = () => {
	const { t } = useTranslation(PAGE_LOGIN)
	const { checkAPIConnectivity } = useUserAction()
	const { loading, isLoggedIn } = useUserData()
	const { formik } = useLogin()
	const [openForgotPass, setOpenForgotPass] = useState(false)
	const { userLogin } = useUserAction()
	const history = useHistory();
	
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const codeParam = searchParams.get('code');
		if (codeParam) {
			axios
			.post(`${API}/el/TokenOrion`, { code: codeParam })
			.then((response) => {
				console.log(response);
				const values = { username: response.data.username, password: 'mpla', aade: 1 }
				userLogin(values)
				history.push(getRouteUrl(ROUTE_PAGE_HOME))
				
			})
			.catch((error) => {
				localStorage.clear();
				sessionStorage.clear();
				document.cookie = "session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				// enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				// 	variant: 'warning', autoHideDuration: 1000,
				// }))
			});
		}
	}, [t]);
	
	useEffect(() => {
		if (!isLoggedIn) checkAPIConnectivity()
	}, [checkAPIConnectivity, isLoggedIn])
	
	return (<Grid container>
			{/* 1η Στήλη */}
			<Grid
				size={{ sm: 3, md: 4 }}
				display={{ xs: 'none', sm: 'flex' }}
				sx={{ flexDirection: 'column' }}
			>
				<LoginCard
					title={t('Project Scope')}
					text={t('The project focuses on the development of digital infrastructure, modern services, and applications that provide parents and contractors with easy access to essential information, such as routes and stops.')}
					src={`${process.env.PUBLIC_URL}/images/antikeimeno_ergou.png`}
				/>
				<LoginCard
					title={t('Project Goal')}
					text={t('The goal is to improve the efficiency and ease of transportation, supporting schools, contractors, parents, and staff. At the same time, the seamless management and organization of transportation by the Region of Western Greece are ensured.')}
					src={`${process.env.PUBLIC_URL}/images/image.png`}
				/>
			
			</Grid>
			{/* 2η Στήλη */}
			<Grid
				size={{ xs: 12, sm: 6, md: 4 }}
			>
				<Box sx={{ backgroundColor: '#f0eee1', height: '100%' }}>
					<Box
						className={"login"}
						bgcolor={'background.default'}
					>
						<Box
							className={'login-box'}
							bgcolor={'#f0eee1'}
						>
							<Box p={1}>
								<LanguageSelect/>
							</Box>
							<Logo
								style={{
									paddingTop: '1rem', maxHeight: '140px', width: 'auto'
								}}
							/>
							<Box>
								{/*<Box pb={2}>*/}
								{/*	<Typography*/}
								{/*		variant="h1"*/}
								{/*		component={'div'}*/}
								{/*		color={"secondary.main"}*/}
								{/*		sx={{*/}
								{/*			letterSpacing: 1.6,*/}
								{/*			textAlign: 'center',*/}
								{/*			textShadow: '0px 6px 4px rgba(0,0,0,0.2)'*/}
								{/*		}}*/}
								{/*	>*/}
								{/*		{t("Student Portal")}*/}
								{/*	</Typography>*/}
								{/*</Box>*/}
								<Box>
									<Typography sx={{
										letterSpacing: 1.0,
										textAlign: 'center',
										textShadow: '0px 4px 4px rgba(0,0,0,0.2)',
										fontWeight: '500',
										fontSize: '16px'
									}}>
										{t('Digital System of Multilevel Information and Management of Student Transportation of the Western Greece Region')}
									</Typography>
								</Box>
								<Form
									formik={formik}
									loading={loading}
								/>
							</Box>
							<Box>
								<Typography
									component={Button}
									onClick={() => setOpenForgotPass(true)}
									variant="body1"
									color={'secondary'}
								>
									{t("Forgot password? Click here.")}
								</Typography>
							</Box>
						</Box>
						<ForgotPassword
							open={openForgotPass}
							handleDialog={(newValue) => setOpenForgotPass(newValue)}
						/>
					</Box>
				</Box>
			</Grid>
			{/* 3η Στήλη */}
			<Grid
				size={{ sm: 3, md: 4 }}
				display={{ xs: 'none', sm: 'flex' }}
				sx={{ flexDirection: 'column' }}
			>
				
				<LoginCard
					title={t('Benefits for Parents')}
					text={t('Parents gain full information and control over their children`s transportation with direct access to data, such as pick-up points, via an interactive map, and the ability to monitor and declare their address.')}
					src={`${process.env.PUBLIC_URL}/images/Κηδεμόνας.png`}
				/>
				<LoginCard
					title={t('Benefits for Contractors')}
					text={t('Contractors gain a complete overview of their routes and immediate access to the completed itineraries.')}
					src={`${process.env.PUBLIC_URL}/images/Aνάδοχοι.png`}
				/>
			
			</Grid>
		</Grid>)
}

export default Login