import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.CONTRACTORS]: {
		el: {
			'Group': 'Ομάδα',
			'Start Date': 'Έναρξη',
			'End Date': 'Λήξη',
			'Back Route': 'Δρομολόγιο Επιστροφής',
			'Αssignment Price' : 'Τιμή Ανάθεσης',
			'Math Price' : 'Τιμή μαθηματικού Τύπου',
			'There are not data!' : 'Δεν υπάρχουν δεδομένα!',
			'There are not dates!' : 'Δεν υπάρχουν ημερομηνίες!',
			'Dates' : 'Ημερομηνίες',
			'Price' : 'Τιμή',
			'Route Details':'Λεπτομέρειες Δρομολογίου',
			'TIN Contractor' : 'ΑΦΜ Αναδόχου',
			'Get easy access to route details and ensure smooth transport execution.' : 'Αποκτήστε εύκολη πρόσβαση σε λεπτομέρειες δρομολογίων και εξασφαλίστε την ομαλή εκτέλεση των μεταφορών.',
		},
		en: {}
	},
}

export default locales