import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.PAGE_LOGIN]: {
		el: {
			'Connection': 'Σύνδεση',
			'Login With TaxisNet' : 'Σύνδεση με κωδικούς TaxisNet',
			'Enter your username': 'Εισάγετε το όνομα χρήστη',
			'Password': 'Κωδικός',
			'Login': 'Είσοδος',
			'required': 'Απαιτείται',
			'Required': 'Απαιτείται',
			'Forgot password? Click here.': 'Ξεχάσατε τον κωδικό σας; Πατήστε εδώ.',
			'Cancel': 'Ακύρωση',
			'Password reset': 'Ανάκτηση Κωδικού',
			'reset_password': 'Για να δηλώσετε νέο κωδικό, παρακαλώ εισάγετε το email σας εδώ. Θα λάβετε στο email σας έναν εξαψήφιο κωδικό. <br/> Χρησιμοποιείστε τον εξαψήφιο κωδικό για να δηλώσετε τον νέο κωδικό σας.',
			"Not Found": "Το email που καταχωρήθηκε δεν υπάρχει.",
			'reset_success': 'Ο κωδικός σας έχει αλλάξει επιτυχώς. Προχωρήστε στη σύνδεση με τον νέο κωδικό σας.',
			'request_new_pass_success': 'Ο εξαψήφιος κωδικός έχει αποσταλεί επιτυχώς στο email σας. Αν δεν βλέπετε το email, παρακαλώ ελέγξτε τους φακέλους των SPAM ή JUNK emails.',
			'Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character': 'Ο κωδικός πρόσβασης πρέπει να αποτελείται από 7-16 λατινικούς χαρακτήρες και να περιέχει τουλάχιστον 3 από τα ακόλουθα. -Κεφάλαιο -Πεζό χαρακτήρα -Αριθμητικό ψηφίο -Ειδικός χαρακτήρας',
			'Passwords must match': 'Οι κωδικοί πρέπει να ταιριάζουν',
			'Six digit code': 'Εξαψήφιος κωδικός',
			'New password': 'Νέος κωδικός',
			'Confirm password': 'Επαλήθευση κωδικού',
			'Submit New Password': 'Υποβολή Νέου Κωδικού',
			'Send 6 Digit Code': 'Αποστολή Εξαψήφιου Κωδικού',
			'Connection to API failed!': 'Η επικοινωνία με το API απέτυχε!',
			'Insert a valid email': 'Εισάγετε ένα έγκυρο email',
			'Wrong 6 digit password!': 'Μη έγκυρος ή ληγμένος εξαψήφιος κωδικός!',
			'Enter your email': 'Εισαγωγή email',
			'msg.login_notfound' : 'Λανθασμένα στοιχεία πρόσβασης',
			'Unable to connect to ITYE API wrong credentials' : 'Αδυναμία σύδεσης στο ITYE API λόγω λανθασμένων διαπιστευτηρίων',
			'Project Scope' : 	'Αντικείμενο του έργου' ,
			'The project focuses on the development of digital infrastructure, modern services, and applications that provide parents and contractors with easy access to essential information, such as routes and stops.': 'Το έργο επικεντρώνεται στην ανάπτυξη ηλεκτρονικών υποδομών σύγχρονων υπηρεσιών και εφαρμογών, παρέχοντας σε γονείς και αναδόχους εύκολη πρόσβαση σε σημαντικές πληροφορίες, όπως δρομολόγια και στάσεις.' ,
			'Project Goal':'Στόχος του έργου',
			'The goal is to improve the efficiency and ease of transportation, supporting schools, contractors, parents, and staff. At the same time, the seamless management and organization of transportation by the Region of Western Greece are ensured.':'Στόχος είναι η βελτίωση της αποτελεσματικότητας και της ευκολίας μεταφοράς, με υποστήριξη σε σχολεία, αναδόχους, κηδεμόνες και υπαλλήλους.Παράλληλα, διασφαλίζεται η αποδοτική διαχείριση και οργάνωση των μεταφορών από την Περιφέρεια Δυτικής Ελλάδας.',
			'Benefits for Parents' : 'Oφέλη για Κηδεμόνες' ,
			'Parents gain full information and control over their children`s transportation with direct access to data, such as pick-up points, via an interactive map, and the ability to monitor and declare their address.': 'Οι κηδεμόνες αποκτούν πλήρη ενημέρωση και έλεγχο για την μεταφορά των παιδιών τους,με άμεση πρόσβαση σε δεδομένα όπως οι στάσεις παραλαβής μέσω διαδραστικού χάρτη, και με δυνατότητα ελέγχου και δήλωσης της διεύθυνσής τους.' ,
			'Benefits for Contractors' :'Οφέλη για Αναδόχους' ,
			'Contractors gain a complete overview of their routes and immediate access to the completed itineraries.' : 'Οι ανάδοχοι αποκτούν πλήρη εικόνα των δρομολογίων τους και άμεση πρόσβαση στις πραγματοποιηθείσες διαδρομές τους.',
			'Digital System of Multilevel Information and Management of Student Transportation of the Western Greece Region' : 'Ψηφιακό Σύστημα Πολυεπίπεδης Πληροφόρησης Και Διαχείρισης της Μεταφοράς Μαθητών της Περιφέρειας Δυτικής Ελλάδας',
			'An error occurred!' : 'Παρουσιάστηκε σφάλμα!',
			
		},
		en: {
			"Not Found": "Email not found.",
			'reset_success': 'Password reset successful. Please proceed to login.',
			'request_new_pass_success': 'The 6 digit code was successfully send to your email. If you cannot find this email, please check SPAM or JUNK folders in your email provider.',
			'required': 'Required',
			'msg.login_notfound' : 'Incorrect Login Credentials',
		 	'Project Scope': 	'Project Scope' ,
			'The project focuses on the development of digital infrastructure, modern services, and applications that provide parents and contractors with easy access to essential information, such as routes and stops.': 'The project focuses on the development of digital infrastructure, modern services, and applications that provide parents and contractors with easy access to essential information, such as routes and stops.' ,
			'Project Goal':'Project Goal',
			'The goal is to improve the efficiency and ease of transportation, supporting schools, contractors, parents, and staff. At the same time, the seamless management and organization of transportation by the Region of Western Greece are ensured.':'The goal is to improve the efficiency and ease of transportation, supporting schools, contractors, parents, and staff. At the same time, the seamless management and organization of transportation by the Region of Western Greece are ensured.',
			'Benefits for Parents' : 'Benefits for Parents',
			'Parents gain full information and control over their children`s transportation with direct access to data, such as pick-up points, via an interactive map, and the ability to monitor and declare their address.' : 'Parents gain full information and control over their children`s transportation with direct access to data, such as pick-up points, via an interactive map, and the ability to monitor and declare their address.' ,
			'Benefits for Contractors' :'Benefits for Contractors' ,
			'Contractors gain a complete overview of their routes and immediate access to the completed itineraries.' : 'Contractors gain a complete overview of their routes and immediate access to the completed itineraries.'
			
			
			
		}
	},
	[Namespaces.PAGE_HOME]: {
		el: {
			'Username': 'Όνομα Χρήστη',
			'My Profile': 'Το Προφίλ μου'
		},
		en: {}
	},
	[Namespaces.PAGE_CONTACT]: {
		el: {
			'Contact': 'Επικοινωνία'
		},
		en: {}
	},
	[Namespaces.PAGE_PRIVACY]: {
		el: {
			'Privacy Policy': 'Πολιτική Απορρήτου',
			'Personal Data Protection': 'Προστασία Προσωπικών Δεδομένων',
			'privacy_text': 'Περισσότερα για την πολιτική απορρήτου εδώ..'
		},
		en: {
			'privacy_text': 'More about privacy here..'
		}
	},
}

export default locales