import {useRef, useState} from 'react';
import {
	Avatar,
	Box,
	ButtonBase,
	ClickAwayListener,
	List,
	ListItemButton,
	ListItemText,
	Paper,
	Popper,
	Stack,
	useTheme
} from '@mui/material';
import useUserAction from "../../../hooks/user/useUserAction";
import useUserData from "../../../hooks/user/useUserData";
import Transitions from "../../@extended/transitions";
import MainCard from "../../mui/general/mainCard/mainCard";
import {AccountCircleOutlined, CallOutlined, LockOutlined, LogoutOutlined} from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";
import {ROUTE_PAGE_CONTACT, ROUTE_PAGE_PRIVACY, ROUTE_PAGE_PROFILE} from "../../../routers/routes";
import {getRouteUrl} from "../../../helpers/routing/getRouteUrl";
import ListItemIcon from "@mui/material/ListItemIcon";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../locales/components/namespaces";

const AccountMenu = () => {
	const theme = useTheme();
	const {userLogout} = useUserAction()
	const {t} = useTranslation(NAVIGATION)
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	
	const {taxRegNo} = useUserData();
	console.log(taxRegNo);
	
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};
	
	const handleLogout = () =>{
		userLogout();
		//TODO:: να μπει στήλη που να διαχωρίζει με τι έχει κάνει είσοδο. Αν έχει μπει μέσω ΑΑΔΕ κάνει redirect, διαφορετικά όχι.
		if (taxRegNo){
			window.location.href  = 'https://test.gsis.gr/oauth2server/logout/TZG6PX31093/?url=https://xxx.gr'
		}
		
	}
	return (
		<Box sx={{flexShrink: 0, ml: 0.75}}>
			<ButtonBase
				sx={{
					borderRadius: 1,
					'&:hover': {
						bgcolor: 'secondary.lighter',
						color: 'primary.darker'
					},
					'&:focus-visible': {
						outline: `2px solid ${theme.palette.secondary.dark}`,
						outlineOffset: 2
					}
				}}
				aria-label="open profile"
				ref={anchorRef}
				aria-controls={open ? 'profile-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
			>
				<Stack direction="row" spacing={2} alignItems="center" sx={{p: 0.5}}>
					<Avatar alt="profile user" size="xs"/>
				</Stack>
			</ButtonBase>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({TransitionProps}) => (
					<Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
						<Paper
							sx={{
								boxShadow: theme.customShadows.z1,
								width: 290,
								minWidth: 240,
								maxWidth: 290,
								[theme.breakpoints.down('md')]: {
									maxWidth: 250
								}
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<MainCard elevation={0} border={false} content={false}>
									<List component="nav" sx={{p: 0, '& .MuiListItemIcon-root': {minWidth: 32}}}>
										{/*<ListItemButton*/}
										{/*	component={Link}*/}
										{/*	to={getRouteUrl(ROUTE_PAGE_PROFILE)}*/}
										{/*>*/}
										{/*	<ListItemIcon>*/}
										{/*		<AccountCircleOutlined/>*/}
										{/*	</ListItemIcon>*/}
										{/*	<ListItemText primary={t("My Profile")}/>*/}
										{/*</ListItemButton>*/}
										<ListItemButton
											component={Link}
											to={getRouteUrl((ROUTE_PAGE_CONTACT))}
										>
											<ListItemIcon>
												<CallOutlined/>
											</ListItemIcon>
											<ListItemText primary={t("Contact")}/>
										</ListItemButton>
										<ListItemButton
											component={Link}
											to={getRouteUrl((ROUTE_PAGE_PRIVACY))}
										>
											<ListItemIcon>
												<LockOutlined/>
											</ListItemIcon>
											<ListItemText primary={t("Privacy policy")}/>
										</ListItemButton>
										<ListItemButton onClick={handleLogout}>
											<ListItemIcon>
												<LogoutOutlined/>
											</ListItemIcon>
											<ListItemText primary={t("Logout")}/>
										</ListItemButton>
									</List>
								</MainCard>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</Box>
	);
};

export default AccountMenu;
