// @flow
import { Alert, Typography } from "@mui/material";
import React, { useContext } from 'react';
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
	view_admin_dashboard,
	view_contractor_dashboard,
	view_director_dashboard,
	view_parent_dashboard, view_perifereia_dashboard
} from "../../helpers/rights";
import { getRouteUrl } from "../../helpers/routing/getRouteUrl";
import useUserData from "../../hooks/user/useUserData";
import { ANNOUNCEMENTS } from "../../modules/announcements/locales/namespaces";
import AnnouncementsIndex from "../../modules/announcements/pages/announcementsIndex";
import News from "../../modules/announcements/pages/news";
import { ROUTE_ANNOUNCEMENT_NEWS } from "../../modules/announcements/routes/routes";
import Index from "../../modules/contractors/pages/index";
import DirectorDashboard from "../../modules/directors/pages/directorDashboard";
import ParentDashboard from "../../modules/parents/pages/parentDashboard/parentDashboard";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import { TitleContext } from "../main/content";

type Props = {};

const Home = (props: Props) => {
	const { username } = useUserData();
	const { count } = useContext(TitleContext)
	const { t } = useTranslation(ANNOUNCEMENTS)
	const history = useHistory();
	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap'
			}}
			width={'100%'}
		>
			<PermissionHOC requiredPermissions={[view_parent_dashboard]}>
				{count > 0 &&
					<Box sx={{width: '100%',justifyContent: 'center',display: 'flex'}} onClick={()=>history.push(getRouteUrl(ROUTE_ANNOUNCEMENT_NEWS))}>
						<Alert variant="outlined" severity="warning"
							   sx={{ width: '40%', justifyContent: 'center', padding: '0px' }}>
							{t('You have')} {count} {count > 1 ?  t('announcements!') : t('announcement!') }
						</Alert>
					</Box>
				}
				<ParentDashboard/>
			</PermissionHOC>
			<PermissionHOC requiredPermissions={[view_contractor_dashboard]}>
				{count > 0 &&
					<Box sx={{width: '100%',justifyContent: 'center',display: 'flex'}} onClick={()=>history.push(getRouteUrl(ROUTE_ANNOUNCEMENT_NEWS))}>
						<Alert variant="outlined" severity="warning"
							   sx={{ width: '40%', justifyContent: 'center', padding: '0px' }}>
							{t('You have')} {count}  {count > 1 ?  t('announcements!') : t('announcement!') }
						</Alert>
					</Box>
				}
				<Index/>
			</PermissionHOC>
			
			<PermissionHOC requiredPermissions={[view_director_dashboard]}>
				{count > 0 &&
					<Box sx={{width: '100%',justifyContent: 'center',display: 'flex'}} onClick={()=>history.push(getRouteUrl(ROUTE_ANNOUNCEMENT_NEWS))}>
						<Alert variant="outlined" severity="warning"
							   sx={{ width: '40%', justifyContent: 'center', padding: '0px' }}>
							{t('You have')} {count}  {count > 1 ?  t('announcements!') : t('announcement!') }
						</Alert>
					</Box>
				}
				{/*<DirectorDashboard/>*/}
				<News/>
			</PermissionHOC>
			
			<PermissionHOC requiredPermissions={[view_admin_dashboard,view_perifereia_dashboard]}>
				{/*<>Admin Dashboard</>*/}
				{/*<Box sx={{ textAlign: 'center', backgroundColor: 'lightblue', width: '100%' }}>*/}
				{/*	<Typography sx={{ fontSize: '20px' }}>{username}</Typography>*/}
				{/*</Box>*/}
				<AnnouncementsIndex/>
			</PermissionHOC>
		</Box>
	);
};

export default Home