import { useTranslation } from "react-i18next";
import { PAGE_LOGIN } from "../../locales/pages/namespaces";
import * as yup from "yup";
import { useFormik } from "formik";
import useUserAction from "../user/useUserAction";

const useLogin = () => {
	const { t } = useTranslation(PAGE_LOGIN)
	const { userLogin } = useUserAction()
	const onSubmit = (values) => {
		userLogin(values)
	}
	
	const validationSchema = yup.object({
		username: yup
		.string()
		.required(t('Required')),
		password: yup
		.string()
		.required(t('Required'))
	})
	
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	return {
		formik
	}
}

export default useLogin