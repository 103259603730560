// @flow
import { FormControl, FormHelperText, FormLabel, TextField, Typography } from "@mui/material";
import './form.css'
import Box from "@mui/material/Box";
import { ErrorMessage, FormikProps, FormikProvider } from "formik";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { formControlDefaults, textFieldDefaults } from "../../../../helpers/forms/defaultProps";
import { PAGE_LOGIN } from "../../../../locales/pages/namespaces";
import PasswordInput from "../../../mui/form/passwordInput/passwordInput";
import axios from "axios";

type Props = {
	formik: FormikProps,
	loading: Boolean
};

const Form = (props: Props) => {
	const { formik, loading } = props
	const { t } = useTranslation(PAGE_LOGIN)

		const handleClick = () => {
			window.location.href =
				'https://test.gsis.gr/oauth2server/oauth/authorize?response_type=code&client_id=TZG6PX31093&state=cg123&scope=read&redirect_uri=https%3A%2F%2Fpdestudents.dynacomp2.eu%2Fmyoauth2client%2FmyUserInfo';
		};

	return (
		<FormikProvider value={formik}>
			<Box
				className={'login-form'}
				sx={{
					padding: {
						xs: '6px 14px',
						sm: '12px 28px',
						md: '46px'
					}
				}}
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<Typography
					sx={{
						marginBottom: 2,
						fontWeight: 400,
						alignSelf:'center',
						cursor: 'pointer',
						textAlign:'center'
					}}
					onClick={handleClick}
					color={'secondary'}
					variant={'h4'}
					componend={'div'}
				>
					{t('Login With TaxisNet')}
				</Typography>
				<Box
					className={'login-inputs-container'}
				>
					<FormControl
						{...formControlDefaults}
					>
						<FormLabel htmlFor={'username'}>{t('Username')}</FormLabel>
						<TextField
							{...textFieldDefaults}
							autoFocus
							name={'username'}
							value={formik.values.username}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							placeholder={t('Enter your username')}
							slotProps={{
								htmlInput: {
									maxLength: 250,
									"aria-label": t('Username')
								},
								input: {
									autoComplete: 'username'
								}
							}}
							required
							sx={{
								'& .MuiOutlinedInput-root': {
									':focus-within': { border: '0.0px solid #ffffff !important' },
									bgcolor: "background.paper"
								},
							}}
						/>
						<FormHelperText>
							<ErrorMessage name={'username'}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						{...formControlDefaults}
					>
						<FormLabel>{t('Password')}</FormLabel>
						<PasswordInput
							formik={formik}
							allowBrowserAutocomplete
							name={'password'}
							required
							label={t('Password')}
							otherProps={{
								size: 'small',
								placeholder: t('Password'),
								...textFieldDefaults
							}}
						/>
						<FormHelperText>
							<ErrorMessage name={'password'}/>
						</FormHelperText>
					</FormControl>
					<LoadingButton
						color={'secondary'}
						loading={loading}
						fullWidth
						variant="contained"
						type={'submit'}
						sx={{
							paddingTop: '10px',
							paddingBottom: '10px',
						}}
					>
						{t('Login')}
					</LoadingButton>
				</Box>
			</Box>
		</FormikProvider>
	);
};

export default Form

