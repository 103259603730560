// @flow
import { useDispatch } from 'react-redux'
import * as actions from '../../actions/actions'
import { useCallback } from "react";
import { getCurrentUserPermissions } from "../../modules/permission/actions/actions";
import useUserData from "./useUserData";

const useUserAction = () => {
	const dispatch = useDispatch()
	const {apiITYEToken} = useUserData()
	
	const setUserLanguage = useCallback((language) => {
		dispatch(actions.setUserLanguage(language))
	}, [dispatch])
	
	const setUserMessage = useCallback((message) => {
		dispatch(actions.setUserMessage(message))
	}, [dispatch])
	
	const setResetPassword = useCallback((newValue) => {
		dispatch(actions.setResetPassword(newValue))
	}, [dispatch])
	
	const checkAPIConnectivity = useCallback(() => {
		dispatch(actions.checkAPIConnectivity())
	}, [dispatch])
	
	const userLogout = useCallback(() => {
		dispatch(actions.userLogout())
	}, [dispatch])
	
	const userLogin = useCallback((values) => {
		const bodyFormData = new FormData();
		bodyFormData.append('username', values.username);
		bodyFormData.append('password', values.password);
		values.aade && bodyFormData.append('aade', 1);
		dispatch(actions.userLogin(bodyFormData))
	}, [dispatch])
	
	const requestResetPassword = useCallback((values) => {
		dispatch(actions.requestResetPassword(values))
	}, [dispatch])
	
	const resetPassword = useCallback((values) => {
		dispatch(actions.resetPassword(values))
	}, [dispatch])
	
	const getUserPermissions = useCallback(() => {
		dispatch(getCurrentUserPermissions())
	}, [dispatch])
	
	const getUserDetails = useCallback((storeId) => {
		dispatch(actions.getUserDetails({
			AddressId: storeId
		}))
	}, [dispatch])
	
	const loginToITYEApi = useCallback(({username, password}) => {
		if(!apiITYEToken) {
			dispatch(actions.loginToITYEApi({
				username: username,
				pass: password
			}))
		}
	}, [apiITYEToken, dispatch])
	
	return {
		setUserLanguage,
		setUserMessage,
		setResetPassword,
		checkAPIConnectivity,
		userLogout,
		userLogin,
		requestResetPassword,
		resetPassword,
		getUserPermissions,
		getUserDetails,
		loginToITYEApi
	}
}

export default useUserAction