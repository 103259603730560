// @flow
import Grid from "@mui/material/Grid2";
import axios from "axios";
import dayjs from "dayjs";
import React, { useState } from 'react';
import { Box, Checkbox, Divider, FormControlLabel, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import QuickViewInfoWrap from "../../../../components/mui/app/quickViewInfoWrap/quickViewInfoWrap";
import MainCard from "../../../../components/mui/general/mainCard/mainCard";
import Modal from "../../../../components/mui/general/modal/modal";
import SkeletonLoaderWrapper from "../../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { view_contractor_dashboard } from "../../../../helpers/rights";
import useUserData from "../../../../hooks/user/useUserData";
import { withPermissionHOC } from "../../../permission/components/permissionHOC/permissionHOC";
import useRoutes from "../../hooks/useRoutes";
import './index.css'
import { CONTRACTORS } from "../../locales/namespaces";
import * as moment from 'moment'
import Details from "../details/details";

type Props = {};

const Index = (props: Props) => {
	const { routesData, loading } = useRoutes()
	const { t } = useTranslation(CONTRACTORS);
	const [showDetails, setShowDetails] = useState(false);
	const [route, setRoute] = useState(null);
	const [rid, setRid] = useState(null);
	
	const { apiITYEToken,taxRegNo } = useUserData()

	
	const routeDetails = (route) => {
		setRid(route.rid);
		let startDate = dayjs(route?.hmeromhnia_enarkshs).format('YYYY-MM-DD');
		let endDate = dayjs(route?.hmeromhnia_lhkshs).format('YYYY-MM-DD')
		
		let url = `https://api-devtransport-pde.cti.gr/api/openapi/contractor/routepricing?afm=` + taxRegNo
		if (route.hmeromhnia_enarkshs) {
			url = url + `&start_date=` + startDate
		}
		if (route.hmeromhnia_lhkshs) {
			url = url + `&end_date=` + endDate
		}
		
		axios.request({
			headers: {
				Authorization: `Bearer ${apiITYEToken}`
			},
			method: "GET",
			// url: `https://api-devtransport-pde.cti.gr/api/openapi/contractor/routepricing?afm=`+ afm + `&start_date=` + startDate + `&end_date=` + endDate
			url: url
		}).then(response => {
			setRoute(response.data.data);
			setShowDetails(true);
		}).catch(error => {
		
		})
		
	}
	const closeQuickView = () => {
		setRoute(null);
		setRid(null);
		setShowDetails(false);
	}
	return (
		<Grid sx={{ minWidth: '100%' }}>
			
			<SkeletonLoaderWrapper loading={loading}>
				<Box sx={{ margin: '20px' }}>
					<Typography
						sx={{
							backgroundColor: 'bisque',
							fontStyle: 'italic',
							// letterSpacing: 1.6,
							fontSize: '20px',
							textAlign: 'center',
						}}>
						{t('Get easy access to route details and ensure smooth transport execution.')}</Typography>
				
				</Box>
				<Box sx={{ marginLeft: '20px' }}>
					<Typography><b>{t('TIN Contractor')}:</b> {taxRegNo}</Typography>
				</Box>
				<Box className="cardContents">
					{routesData.map(route => (
							<MainCard
								title={
									<>
										<Typography variant="h4" component={'div'} sx={{ textAlign: 'center' }}>
											{route?.title}
										</Typography>
										<Typography component={'div'} sx={{ textAlign: 'center', fontSize: '14px' }}>
											{route?.descr}
										</Typography>
									</>
								}
								sx={{ flex: 'auto', border: '0px', marginTop: '0px', maxWidth: '400px' }}
							>
								<Box sx={{
									'&:hover': {
										backgroundColor: '#d3d3d37a', // Color on hover
										cursor: 'pointer',           // Changes cursor on hover
									},
								}} onClick={() => routeDetails(route)}>
									<QuickViewInfoWrap
										label={t('Group')}> {route.rgroup ? route.rgroup : '-'}</QuickViewInfoWrap>
									<QuickViewInfoWrap
										label={t('Start Date')}> {route.hmeromhnia_enarkshs ? dayjs(route.hmeromhnia_enarkshs).format("DD/MM/YYYY") : '-'}</QuickViewInfoWrap>
									<QuickViewInfoWrap
										label={t('End Date')}> {route.hmeromhnia_lhkshs ? dayjs(route.hmeromhnia_lhkshs).format("DD/MM/YYYY") : '-'}</QuickViewInfoWrap>
									<QuickViewInfoWrap
										label={t('Back Route')}> {route.dromologio_epistrofhs ? route.dromologio_epistrofhs : '-'}</QuickViewInfoWrap>
								</Box>
							</MainCard>
						)
					)}
				</Box>
				<Modal
					show={showDetails}
					onBackgroundClick={closeQuickView}
				>
					<Details
						route={route}
						rid={rid}
						onClose={closeQuickView}
					/>
				</Modal>
			</SkeletonLoaderWrapper>
		</Grid>
	)
		;
};

export default withPermissionHOC(Index, [view_contractor_dashboard])
// export default Index